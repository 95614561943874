
























import Vue from 'vue';
import Component from 'vue-class-component';
import DadosVeiculo from '@/components/dados/DadosVeiculo.vue';
import DetalhesPlano from '@/components/detalhes/DetalhesPlano.vue';
import DetalhesProsseguir from '@/components/detalhes/DetalhesProsseguir.vue';
import DetalhesTerceiros from '@/components/detalhes/DetalhesTerceiros.vue';
import {
  detalhesPlano,
  detalhesCobertura,
} from '@/components/detalhes/detalhes';

@Component({
  components: {
    DadosVeiculo,
    DetalhesTerceiros,
    DetalhesProsseguir,
    DetalhesPlano,
  },
})
export default class Detalhes extends Vue {
  get detalhesPlano() {
    return detalhesPlano;
  }

  get detalhesCobertura() {
    return detalhesCobertura;
  }

  public mounted() {
    this.$store.commit('SET_SIDEBAR_SHOWING', true);
    this.$store.commit('SET_SIDEBAR_ENABLED', true);

    window.funnelytics.functions.step();
  }

  public visibilityChanged(isVisible: boolean) {
    this.$store.commit(
      'SET_SIDEBAR_SHOWING',
      !(this.$vuetify.breakpoint.xsOnly && isVisible)
    );
  }

  get fipeValor() {
    return this.$store.state.cotacao.valor_fipe;
  }
}
