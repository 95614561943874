





















import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DadosVeiculo extends Vue {
  get cotacao() {
    return this.$store.state.cotacao;
  }

  get marca() {
    return this.$store.state.cotacao.marca;
  }

  get modelo() {
    return this.$store.state.cotacao.modelo;
  }

  get ano() {
    return this.$store.getters.veiculo_ano;
  }

  get valor_fipe() {
    return this.$store.state.cotacao.valor_fipe;
  }

  public getLogoMarca(value: string) {
    try {
      return require('@/assets/marcas/' + value + '.svg');
    } catch (e) {
      return require('@/assets/marcas/Desconhecida.svg');
    }
  }
}
