


































































import { Component, Vue } from 'vue-property-decorator';
import StarIcon from 'vue-material-design-icons/Star.vue';

@Component({
  components: { StarIcon },
  props: {
    item: {},
  },
})
export default class DetalhesPlano extends Vue {}
