




































import { Component, Vue } from 'vue-property-decorator';
import StarIcon from 'vue-material-design-icons/Star.vue';

@Component({
  components: { StarIcon },
})
export default class DetalhesTerceiros extends Vue {
  public image = require('@/assets/images/crashed-car.svg');
  public readonly coberturas = [
    { label: 'Sem cobertura', value: 0, index: 0 },
    { label: 'R$ 50.000,00', value: 120, index: 1 },
    { label: 'R$ 100.000,00', value: 180, index: 2 },
    { label: 'R$ 150.000,00', value: 200, index: 3 },
    { label: 'R$ 200.000,00', value: 230, index: 4 },
    { label: 'R$ 250.000,00', value: 250, index: 5 },
    { label: 'R$ 300.000,00', value: 280, index: 6 },
    { label: 'R$ 400.000,00', value: 310, index: 7 },
  ];

  get cobertura() {
    return this.$store.state.cotacao.valor_cobertura;
  }

  set cobertura(value) {
    const data = this.coberturas.find((item) => item.value === value);
    if (data !== undefined) {
      this.$store.commit('SET_COBERTURA', data);
    }
  }
}
