const detalhesPlano = [
  {
    index: 0,
    title: 'Colisão',
    subtitle: 'Serviços de cobertura',
    image: require('@/assets/images/crashed-car.svg'),
    text:
      'Nossa cobertura contra colisões* irá garantir que, ao caso de sisnistro, você consiga consertar seu veículo no menor tempo possível, em uma das nossas oficinas credenciadas**, com o menor esforço e no menor tempo possível. Todos os nossos profissionais estão empenhados em ajudá-lo minimizando suas frustações.',
    indenizacao: true,
    items: [
      {
        title: '*Qualquer Batida',
        text:
          'Em caso de colisões, você poderá contar, através da nossa central 24h, com nosso serviço de reboque, transporte para retirada do veículo, destombamento, transmissão de mensgaens, retorno a domicílio, remoção inter hospitalar e até mesmo com nosso serviço de táxi.',
      },
      {
        title: '**Perda Total',
        text:
          'Em caso de perda total em batidas, tombamento, queda de veículo ou queda de qualquer objeto que cause a perda total do seu veículo, essa cobertura garante que você receba o valor que contratou da proteção.',
      },
    ],
  },
  {
    index: 1,
    title: 'Incêndio',
    subtitle: 'Detalhes de cobertura',
    image: require('@/assets/images/burn.svg'),
    text:
      'Se seu veículo der perda total porque pegou fogo, explodiu, ou caiu um raio, você recebe o valor da fipe ou reposição do bem para compensar o prejuízo.',
    indenizacao: true,
  },
  {
    index: 2,
    title: 'Roubo ou Furto',
    subtitle: 'Detalhes de cobertura',
    image: require('@/assets/images/robber.svg'),
    text:
      'A Proteauto oferece a cobertura contra roubo ou furto*, que visa proteger o patrimônio de todos os nossos associados, tão duramente conquistado. Se não for localizado você recebe o valor integral do seu veículo!**',
    indenizacao: true,
  },
];

const detalhesCobertura = [
  {
    index: 3,
    title: 'Assistências 24h',
    subtitle: 'Detalhes',
    image: require('@/assets/images/tow-truck.svg'),
    tags: [
      'Registro de CNVR',
      'Retorno ao domicílio',
      'Translado de corpos',
      'Transmissão de Mensagens',
      'Reboque 300km',
      'Socorro Elétrica',
      'Pane Mecânica',
      'Chaveiro',
      'Envio de acompanhante',
      'Remoção inter hospitalar',
    ],
    text:
      'Em caso de pane elétrica ou mecãnica no seu veículo, você pode acionar até 1 assistência por mês, e em caso de acidente 0 km é ilimitado, com limite de 4 utilizações ao ano',
    indenizacao: false,
  },
  {
    index: 4,
    title: 'Rastreador',
    subtitle: 'Detalhes',
    image: require('@/assets/images/tracker.svg'),
    text:
      'Se seu veículo estiver acima de 95 mil reais, você ganha um rastreador sem custo mensal para monitorar ele onde estiver, e além disso você terá acesso via login e senha no aplicativo.',
    indenizacao: false,
  },
  {
    index: 5,
    title: 'Cobertura de Agregado / Implementos',
    subtitle: 'Detalhes',
    image: require('@/assets/images/cargo.svg'),
    text:
      'Essa cobertura é exclusiva para veículos de carga, criada para você que tem seu caminhão rodando por todo Brasil e busca por uma cobertura mais completa para não deixar nada de fora, seja um 4° eixo, equipamento de guincho, uma carroceria ou até mesmo uma carreta, podera contratar mais essa cobertura para deixar seu bruto bem protegido.',
    indenizacao: false,
  },
  {
    index: 6,
    title: 'Destombamento',
    subtitle: 'Detalhes',
    image: require('@/assets/images/destombamento.svg'),
    text:
      'A gente sabe que não podemos contar sempre com a sorte e por varios motivos um veículo pode vir a tombar ou até mesmo capotar, com essa cobertura você garanta que se isso acontecer todas as despesas fica por cont da Proteauto.',
    indenizacao: false,
  },
  {
    index: 7,
    title: 'Sem perfil de condutor',
    subtitle: 'Detalhes',
    image: require('@/assets/images/driver.svg'),
    text:
      'Nossa proteção é totalmente desburocratizada e não trabalhamos com perfil de condutor, é isso mesmo, você não precisa ficar informando sempre quem é o condutor, desde que o mesmo esteja habilitado para conduzir o veículo.',
    indenizacao: false,
  },
  {
    index: 8,
    title: 'Cobertura nacional 24h',
    subtitle: 'Detalhes',
    image: require('@/assets/images/brazil.svg'),
    text:
      'Aqui na Proteauto além de um atendimento humanizado, nós te damos um cobertura completa em todo territorio nacional sem restrição de horários ou rota, porque sabemos que para o trabalho não existe hora marcada nem rota certa.',
    indenizacao: false,
  },
];

export { detalhesPlano, detalhesCobertura };
